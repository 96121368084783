import kotlinx.browser.document
import kotlinx.browser.window
import org.w3c.dom.url.URLSearchParams
import kotlin.js.Date
import kotlin.time.Duration.Companion.minutes

fun setCookie() = render {
    val params = URLSearchParams(window.location.search)
    val key = params.get("code")

    val expiry = Date(Date().getTime() + 10.minutes.inWholeMilliseconds).toUTCString()

    document.cookie = "KEY=$key; expires=$expiry; path=/"

    val daddy = window.self
    daddy.opener = window.self
    daddy.close()
}
