import kotlinx.browser.document
import kotlinx.browser.window
import kotlinx.html.DIV
import kotlinx.html.dom.append
import kotlinx.html.id
import kotlinx.html.js.div

/*
 * (c) Copyright 2021 Homedia. All Right Reserved.
 */

/**
 * Renders the given [DIV] [block] with the [id].
 */
fun render(id: String = "content", requireLogin: Boolean = false, block: DIV.() -> Unit) {
    if (requireLogin) {
        if(getCookie("KEY").isNullOrBlank()) {
            document.location!!.href = "/login?url=${encodeURIComponent(document.location!!.href)}"
            return
        }
    }

    window.addEventListener(
        "load",
        {
            @Suppress("ReplaceNotNullAssertionWithElvisReturn") // we know a body is in the HTML
            document.body!!.append.div {
                this.id = id
                block()
            }
        }
    )
}

fun getCookie(name: String): String? {
    val value = "; ${document.cookie}"
    val parts = value.split("; ${name}=")
    if (parts.size == 2) return parts.last().split(';').first()
    return null
}

