import com.homedia.backend.wrapper.Homedia
import com.homedia.importer.BuildConfig
import kotlinx.browser.window
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.SupervisorJob
import kotlinx.html.p
import kotlin.coroutines.CoroutineContext

/*
 * (c) Copyright 2021 Homedia. All Right Reserved.
 */

const val apiUrlCookie = "API_URL"
val homediaApiUrl: String get() = getCookie(apiUrlCookie) ?: BuildConfig.WEBSITE_URLS.first()

/**
 * Homedia api.
 */
val homedia: Homedia = Homedia {
    url(homediaApiUrl)

    importTokenProvider = {
        getCookie("KEY") ?: error("Currently no active session ")
    }
}

/**
 * UI entry point.
 */
fun main() {
    val url = window.location.href
    console.log(url)
    when {
        "view_job" in url -> renderWatch()
        "login" in url -> renderLogin()
        "files" in url -> renderFilesPage()
        "set_cookie" in url -> setCookie()
        "logout" in url -> logout()
        else -> {
            render {
                p { +"This is the importer main page, please go to /files to view all files" }
            }
        }
    }
}

object ImporterScope : CoroutineScope {
    override val coroutineContext: CoroutineContext = Dispatchers.Main + SupervisorJob()
}
