
import com.homedia.backend.common.import.JobStats
import kotlinx.browser.document
import kotlinx.browser.window
import kotlinx.coroutines.promise
import kotlinx.html.FlowContent
import kotlinx.html.FlowOrPhrasingContent
import kotlinx.html.br
import kotlinx.html.button
import kotlinx.html.id
import kotlinx.html.js.onClickFunction
import kotlinx.html.p
import kotlinx.html.textArea
import org.w3c.dom.HTMLButtonElement
import org.w3c.dom.url.URLSearchParams

/*
 * (c) Copyright 2021 Homedia. All Right Reserved.
 */

/**
 * Renders the job watch page.
 */
fun renderWatch(): Unit = render(requireLogin = true) {
    update()
    stats()
    logs()
    br()
    logoutButton()
}

@Suppress("MagicNumber")
private fun update() {
    var interval = -1

    fun update(job: JobStats) {
        document.getElementById("total")?.innerHTML = "Total: " + job.total
        document.getElementById("errored")?.innerHTML = "Errored: " + job.errored
        document.getElementById("successed")?.innerHTML = "Successed: " + job.successed

        if (job.done) {
            (document.getElementById("logout") as HTMLButtonElement).disabled = false
            window.clearInterval(interval)
        }

        val newLog = job.log.joinToString("\n") { it.message }
        val log = document.getElementById("log") ?: return
        if (newLog != log.innerHTML) {
            log.innerHTML = newLog
            log.scrollTop = log.scrollHeight.toDouble()
        }
    }

    interval = window.setInterval(
        {
            val jobId = URLSearchParams(window.location.search).get("job_id")!!
            ImporterScope.promise {
                homedia.import.getJobStats(jobId)
            }.then { update(it) }
        },
        500
    )
}

private fun FlowContent.stats() {
    p {
        id = "total"
        +"Total: ..."
    }

    p {
        id = "successed"
        +"Success: ..."
    }

    p {
        id = "errored"
        +"Error-ed: ..."
    }
}

private fun FlowOrPhrasingContent.logs() {
    textArea("15", "100") {
        id = "log"
        readonly = true
    }
}

private fun FlowOrPhrasingContent.logoutButton() {
    button {
        disabled = true
        id = "logout"

        onClickFunction = {
            window.location.href = "/logout"
        }

        p {
            +"Logout"
        }
    }
}
