import com.homedia.backend.common.import.ImportType
import com.homedia.backend.common.import.ImportableFile
import kotlinx.browser.document
import kotlinx.browser.window
import kotlinx.coroutines.promise
import kotlinx.html.FlowOrInteractiveOrPhrasingContent
import kotlinx.html.TagConsumer
import kotlinx.html.button
import kotlinx.html.dom.append
import kotlinx.html.h3
import kotlinx.html.id
import kotlinx.html.js.onClickFunction
import kotlinx.html.js.tr
import kotlinx.html.option
import kotlinx.html.p
import kotlinx.html.select
import kotlinx.html.table
import kotlinx.html.td
import kotlinx.html.th
import org.w3c.dom.HTMLOptionElement
import org.w3c.dom.HTMLSelectElement
import org.w3c.dom.get

/*
 * (c) Copyright 2021 Homedia. All Right Reserved.
 */

/**
 * Renders the files UI listing all importable files
 */
fun renderFilesPage(): Unit = render("main", true) {
    h3 { +"Files" }
    p {
        id = "loading"
        +"loading"
    }

    fetcher()
}

private fun fetcher() {
    ImporterScope.promise { homedia.import.getFiles() }.then { load(it) }
}

private fun load(list: List<ImportableFile>) {
    document.getElementById("loading")?.remove()
    document.getElementById("main")?.append {
        table {
            tr {
                th { +"Filename" }
                th { +"Import as" }
                th { +"Start import" }
            }

            list.forEachIndexed { index, (name, id) ->
                tr {

                    td { +name }
                    td {
                        makeSelector(index, name)
                    }
                    td {
                        import(id, index)
                    }
                }
            }
        }
    }
}

private fun <T, C : TagConsumer<T>> C.makeSelector(index: Int, fileName: String) = select {
    id = computeSelectorId(index)
    ImportType.entries.forEach {
        option {
            value = it.name
            +it.displayName
            selected = it.displayName == fileName
        }
    }
}

private fun FlowOrInteractiveOrPhrasingContent.import(fileId: String, index: Int) = button {
    onClickFunction = {
        val itemSelector = document.getElementById(computeSelectorId(index)) as HTMLSelectElement

        val element = itemSelector.options[itemSelector.selectedIndex] as HTMLOptionElement
        val name = element.value
        ImporterScope.promise {
            homedia.import.startJob(fileId, ImportType.valueOf(name))
        }.then { id -> window.location.href = "/view_job?job_id=$id" }
    }

    +"Import"
}

private fun computeSelectorId(index: Int) = "import-select-$index"
