/*
 * (c) Copyright 2021 Homedia. All Right Reserved.
 */

import com.homedia.importer.BuildConfig
import kotlinx.browser.document
import kotlinx.browser.window
import kotlinx.html.*
import kotlinx.html.js.onClickFunction
import org.w3c.dom.HTMLOptionElement
import org.w3c.dom.HTMLSelectElement
import org.w3c.dom.get
import org.w3c.dom.url.URLSearchParams
import kotlin.js.Date
import kotlin.time.Duration.Companion.minutes

private const val apiSelectorId = "api_url_selector"

private fun setAndGetApiCookie(): String {
    val selector = document.getElementById(apiSelectorId) as? HTMLSelectElement
    return if (selector != null) {
        val element = selector.options[selector.selectedIndex] as HTMLOptionElement
        element.value.also {
            val expiry = Date(Date().getTime() + 10.minutes.inWholeMilliseconds).toUTCString()

            document.cookie = "$apiUrlCookie=$it; expires=$expiry; path=/"
        }
    } else {
        BuildConfig.WEBSITE_URLS.first()
    }
}

/**
 * Renders the login page.
 */
fun renderLogin() {
    return render {
        if (BuildConfig.WEBSITE_URLS.size > 1) {
            select {
                id = apiSelectorId
                BuildConfig.WEBSITE_URLS.forEach {
                    option {
                        +it
                    }
                }
            }
            br()
        }
        loginButton()

        br()

        refreshButton()
    }
}

private fun FlowOrPhrasingContent.loginButton() {
    button {
        onClickFunction = {
            window.open(
                "${setAndGetApiCookie()}/import/auth", "Login",
                "width=600,height=644,status=yes,scrollbars=yes,resizable=yes"
            )
        }

        p {
            +"Login"
        }
    }
}

private fun FlowOrPhrasingContent.refreshButton() {
    button {
        //language=JavaScript
        onClickFunction = {
            setAndGetApiCookie()
            val origin = URLSearchParams(window.location.search)
            window.location.href = decodeURIComponent(origin.get("url").toString())
        }

        p {
            +"I logged in"
        }
    }
}

/**
 * Decodes an URL encoded component.
 * @param encodedURI the component to decode
 */
external fun decodeURIComponent(encodedURI: String): String

/**
 * Encodes an URL component.
 * @param uri the component to encode
 */
external fun encodeURIComponent(uri: String): String
